<template>
    <div>
        <div class="main-title">会员设置列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">轮播图大小为：980*500</li>
                        <li data-v-69874ad0="">目前只在小程序展示</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l">
                    <el-input v-model="name" class="input" clearable size="small" style="width:200px"></el-input>
                </span>
                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <router-link to="/vipSet/add" class="router-link">
                        <el-button type="primary" size="small">新增会员卡</el-button>
                    </router-link>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table :header-cell-style="{padding:0}" ref="singleTable" :data="tableData" highlight-current-row
                    v-loading="loading" style="width: 100%;background: #fff;">
                    <el-table-column label="ID" property="id">
                    </el-table-column>
                    <el-table-column property="name" label="名称">
                    </el-table-column>
                    <el-table-column property="module" label="会员模块">
                    </el-table-column>
                    <el-table-column property="created_at" label="创建时间">
                    </el-table-column>
                    <el-table-column property="created_at" label="过期时间">
                    </el-table-column>
                  

                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link v-if="scope.row.status === 1" type="primary"
                                    @click="setStatus(scope.row,scope.$index)">下架</el-link>
                                <el-link v-if="scope.row.status === 0" type="primary"
                                    @click="setStatus(scope.row,scope.$index)">上架</el-link>
                            </span>
                            <!-- <span>
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span> - -->
                            <span>
                                <el-link type="primary" @click="vipDetail(scope.row)">详情</el-link>
                            </span>-
                            <span>
                                <el-link type="primary" @click="vipedit(scope.row)">编辑</el-link>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "template-list",
    data() {
        return {
            page: 1,
            pageSize: 10,
            total: 0,
            name: '',
            loading: false,
            imageUrl: config.imageUrl,
            tableData: [],
        }
    },
    methods: {
        ...mapActions('vipSet', ['audioBooksVipList']),
        search() {
            this.page = 1
            this.getList()
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.getList()
        },
        setPage(page) {
            this.page = page
            this.getList()
        },
        vipDetail(row){
            this.$router.push('/vipSet/details/'+row.module)
        },
        vipedit(row){
            this.$router.push('/vipSet/edit/'+row.module)
        },
        async getList() {
            this.loading = true
            const { data } = await this.audioBooksVipList({ name: this.name, page: this.page, pageSize: this.pageSize })
            this.tableData = data.list
            this.total = data.total
            this.loading = false
        },
    },
    mounted() {
        this.getList()
    }
}
</script>

<style>

</style>
